.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}
.cert{
  float:right;
  margin-top:40px;
}


h3{
  margin-bottom:5px;
}
.contact h2{
  margin-top:5px;
}
.contact h1{
  margin-bottom:5px;
}
.skilllist ul{
  list-style-type:none;
  margin:0;
  padding:0;
}
.techskills, .employment, .certifications, .education, .otherresponsibilies{
  clear:both;
  margin: 10px auto;
}
.skilllist{
  float:left;
  margin: 0px auto;
  width:33%;
  }
.datawrapper{
    margin:0 auto;
    max-width: 1200px;
    width:100%;
    display:block;
  }
  .tabs,.MuiTabs-root{
    margin:0 auto;
    max-width: 1200px!important;
    overflow: visible!important;
    display:block!important;
  }
.narrow{
  max-width:800px;
}
.App-header {
  background-color: #fff;
  display: block;

  font-size: calc(10px + 2vmin);
  color: #282c34;
}
.holdheader{
  margin:10px auto;
  max-width: 1200px;
}
.employment li, .otherresponsibilies li{
  text-align: left;
}
.header{
  margin:0 auto;
}
.clearBoth{
  clear:both; 
}
.floatright{
  float:right;
}
.floatleft{
  float:left;
}
resume {
  margin: 0 auto;
  max-width: 1200px;
  clear:both;
}
resume h2{
  text-align: left;
  margin: 5px auto 5px;
  padding-top:25px;
  max-width: 1200px;
  display:none;
}
.contact{
  float:left;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
